<template>
  <div class="table_wrapper">
    <v-card flat tile>
      <v-toolbar class="rounded">
        <v-btn icon :to="'/cms'">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>Cms Edit</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn color="primary" v-on:click="saveAllData" :loading="loading" dark>
          <v-icon>mdi-check</v-icon>
          Save
        </v-btn>
      </v-toolbar>

      <v-layout class="p-5" wrap row>
        <v-col cols="6">
          <v-text-field
            label="Title"
            v-model="data.title"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Slug"
            v-model="data.slug"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            label="Lang"
            v-model="data.lang_id"
            :items="langs"
            item-text="name"
            item-value="id"
            :loading="loading"
            required
          ></v-select>
        </v-col>
        <v-col cols="12">
          <vue-editor v-model="data.content"></vue-editor>
        </v-col>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "CmsEdit",
  components: {
    VueEditor,
  },
  data: () => ({
    loading: true,
    menu: false,
    token: localStorage.getItem("authToken"),
    langs: [],
    data: {
      content: "",
      created_by: null,
      custom_fields: null,
      lang_id: "",
      slug: "",
      title: "",
      id: null,
    },
  }),
  methods: {
    saveAllData: function() {
      this.loading = true;
      this.data.id = Number(this.$route.params.id);

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "page/" +
            this.data.id +
            "&api_token=" +
            this.token,
          this.data
        )
        .then((res) => {
          this.loading = false;
          // this.$router.push({
          //   name: "Coupons",
          // });
        })
        .catch((err) => console.log(err));
    },
    getData: function(id) {
      let url =
        process.env.VUE_APP_API_URL + "page/" + id + "&api_token=" + this.token;
      axios
        .get(url)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((err) => console.log(err));
      this.getLang();
    },
    getLang: function() {
      let url =
        process.env.VUE_APP_API_URL +
        "language/filter?page=1&per_page=1000&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.langs = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
